import { KeywordSeedingReport, Prisma } from "@prisma/client";

type KeywordSeedingReportProductGroup =
  Prisma.KeywordSeedingReportProductGroupGetPayload<{
    include: {
      keyword_seeding_report_values: true;
      product_group: {
        include: {
          shop: true;
        };
      };
    };
  }>;

export const useKeywordSeedingStore = defineStore("keywordSeedingStore", () => {
  const reports = ref<KeywordSeedingReport[]>([] as KeywordSeedingReport[]);
  const productGroups = ref<KeywordSeedingReportProductGroup[]>(
    [] as KeywordSeedingReportProductGroup[]
  );
  const appStore = useAppStore();
  const currentReport = ref<KeywordSeedingReport | null>(null);
  const currentReportProductGroup = ref<KeywordSeedingReportProductGroup>({} as KeywordSeedingReportProductGroup);
  const { isLoading } = storeToRefs(appStore);

  const getReport = async (reportId: string) => {
    isLoading.value = true;
    const { data } = await useFetch(
      `/api/adsmanager-ewo/keyword-seeding/reports/${appStore.selectedShop.id}/${reportId}`,
      {
        method: "get",
      }
    );
    isLoading.value = false;

    if (data.value) {
      currentReport.value = data.value as KeywordSeedingReport;
      return;
    }

    currentReport.value = null;
  };

  const fetchProductGroups = async (reportId: string) => {
    isLoading.value = true;
    const body: Prisma.KeywordSeedingReportProductGroupFindManyArgs = {
      where: {
        report_id: reportId,
        product_group: {
          shop: {
            name: appStore.selectedShop.name,
          },
        },
      },
      orderBy: {
        product_group: {
          product_group_number: "asc",
        },
      },
      include: {
        product_group: {
          include: {
            shop: true,
          },
        },
      },
    };

    const { data, error } = await useFetch(
      "/api/adsmanager-ewo/keyword-seeding/product-groups",
      {
        method: "post",
        body,
      }
    );

    isLoading.value = false;

    if (error.value) {
      throw createError({
        message: "Error fetching product groups" + error.value.message,
        statusCode: 500,
      });
    }
    if (data.value) {
      productGroups.value = data.value as KeywordSeedingReportProductGroup[];
    }
  };

  const fetchReports = async () => {
    isLoading.value = true;
    const body: Prisma.KeywordSeedingReportFindManyArgs = {
      where: {
        keyword_seeding_report_product_group: {
          every: {
            product_group: {
              shop: {
                name: appStore.selectedShop.name,
              },
            },
          },
        },
      },
      include: {
        keyword_seeding_report_product_group: {
          include: {
            product_group: {
              include: {
                shop: true,
              },
            },
          },
        },
      },
    };

    reports.value = await $fetch("/api/adsmanager-ewo/keyword-seeding/reports", {
      method: "post",
      body,
    });
    isLoading.value = false;
  };

  const fetchProductGroupValues = async (
    reportId: string,
    productGroupNumber: string
  ) => {
    isLoading.value = true;
    const body: Prisma.KeywordSeedingReportValuesFindManyArgs = {
      where: {
        keyword_seeding_report_product_groups: {
          report_id: reportId,
          product_group: {
            product_group_number: Number(productGroupNumber),
            shop: {
              name: appStore.selectedShop.name,
            },
          },
        },
      },

      include: {
        keyword_seeding_report_product_groups: true,
      },
    };
    isLoading.value = false;
    return await $fetch("/api/adsmanager-ewo/keyword-seeding/values", {
      method: "post",
      body,
    });
  };

  const removeReportValues = async (ids: string[]) => {
    await $fetch("/api/adsmanager-ewo/keyword-seeding/values", {
      method: "delete",
      body: {
        ids,
      },
    });
  };

  const updateProductGroupStatus = async (id: string, state: boolean) => {
    const body: Prisma.KeywordSeedingReportProductGroupUpdateArgs = {
      where: {

        id
      },
      data: {
        approved: state,
      },
    };
    await useFetch("/api/adsmanager-ewo/keyword-seeding/product-groups", {
      method: "PATCH",
      body,
    });

    await fetchProductGroups(currentReport.value?.id as string);
  };

  const getReportProductGroup = async (reportId: string, productGroupNumber: string) => {

    currentReportProductGroup.value = productGroups.value.find((productGroup) => {
      return productGroup.report_id === reportId && productGroup.product_group.product_group_number === Number(productGroupNumber)
    }) as KeywordSeedingReportProductGroup

  }

  return {
    reports,
    productGroups,
    getReportProductGroup,
    fetchReports,
    getReport,
    fetchProductGroups,
    fetchProductGroupValues,
    removeReportValues,
    updateProductGroupStatus,
    currentReportProductGroup,
    currentReport,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useKeywordSeedingStore, import.meta.hot));
}
